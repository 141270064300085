<template>
  <div class="container mx-auto pb-5 border-b border-primary-grey" :class="$style.scrollBarHidden">
    <TitleWrapper
      :filter-option="true"
      title="NOTIFS"
      tooltip-title="NOTIFS"
      :display-breadcrumb="true"
    >
      <template v-slot:filterItems>
        <div>
          <div
            v-i18n="dashboard"
            class="font-roboto font-normal text-text-color text-base sm:text-xl mb-4"
          >
            Search Notification
          </div>
          <div class="filter-block pb-6">
            <section class="input-field customCalenderSelect">
              <UiSingleSelect title="Notification" label="title" :options="[]" class="flex-1" />
            </section>
          </div>
        </div>
      </template>
    </TitleWrapper>
    <div class="bg-bg-color-white rounded-lg mt-5 px-7 border border-primary-grey pb-10">
      <div
        class="font-roboto font-normal notification-text-color text-lg pt-7 pb-5 flex justify-between w-full"
      >
        <div v-i18n="dashboard">Notifications List</div>
        <div>
          <button
            v-i18n="dashboard"
            class="px-5 py-1.5 text-sm text-primary-purple-700 border border-primary-purple-700 rounded-md"
            @click="markAllAsRead()"
          >
            Mark all as Read
          </button>
        </div>
      </div>
      <!-- HR -->
      <div class="border-t border-primary-grey mb-5"></div>
      <div v-if="newNotifications.length">
        <div v-i18n="dashboard" class="font-roboto font-medium text-label-text text-2xl mb-5">
          New
        </div>
        <NotificationItem
          :increase-size="true"
          :title-limit="isMobile ? 31 : 100"
          :notifications="newNotifications"
          class="rounded-md pb-2 pt-1 flex flex-col gap-2"
          :custom-style="true"
          @notificationAction="notificationAction"
        />
      </div>
      <div>
        <div class="font-roboto font-medium text-label-text text-2xl mb-5 mt-5">Earlier</div>
        <NotificationItem
          :increase-size="true"
          :title-limit="isMobile ? 31 : 100"
          :notifications="notifications"
          class="rounded-md pb-2 pt-1 flex flex-col gap-2"
          :custom-style="true"
          @notificationAction="notificationAction"
        />
      </div>
      <div class="border-t border-primary-grey mt-5">
        <Pagination
          v-if="showPagination"
          :record-limit="filteredRecordLimit"
          :max-range="paginationCounts(notificationCounts, filteredRecordLimit)"
          @filterRecord="filterRecord"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import TitleWrapper from '@components/TitleWrapper.vue'
import Pagination from '@components/BaseComponent/Pagination.vue'
import generalUtil from '@src/mixins/general-mixins.js'
import UiSingleSelect from '@/src/components/UiElements/UiSingleSelect.vue'
import NotificationItem from './NotificationItem.vue'
import { objectDeepCopy } from '@utils/generalUtil'
import { NOTIFICATION_STATUS } from '@src/constants/general-constants'

export default {
  components: {
    TitleWrapper,
    NotificationItem,
    Pagination,
    UiSingleSelect,
  },
  mixins: [generalUtil],
  page: {
    title: 'Notification | All',
    meta: [
      {
        name: 'description',
        content: 'All Notifications Management',
      },
    ],
  },
  data() {
    return {
      isMobile: window.matchMedia('(max-width: 1023px)').matches,
      classSettings: false,
      notifications: [],
      newNotifications: [],
      notificationCounts: null,
      token: localStorage.getItem('jwt'),
      dashboard: 'dashboard',
      showPagination: false,
      isLoading: false,
      filteredRecordLimit: 10,
    }
  },
  computed: {
    ...mapState('layout', [
      'activeRole',
      'currentCampusScope',
      'currentSectionScope',
      'tabLeftNav',
    ]),
    ...mapState({
      notificationsFromStore: (state) => state.notifications.allnotifications,
      newNotificationsFromStore: (state) => state.notifications.newNotifications,
    }),
    ...mapState('auth', ['currentUser']),
  },
  watch: {
    notificationsFromStore: {
      handler() {
        this.notifications = objectDeepCopy(this.notificationsFromStore)
      },
      immediate: true,
    },
    newNotificationsFromStore: {
      handler() {
        this.newNotifications = objectDeepCopy(this.newNotificationsFromStore)
      },
      immediate: true,
    },
  },
  created() {
    /*
     *In created hook we call the method to initialize the notification through API
     *Default limit to get notification list is 10
     */
    this.filterRecord()
    /*
     *Set rightBar Data
     */
    const rightBarContent = {
      header: {
        title: 'Notifications',
        buttons: [
          {
            title: 'Settings',
            classes: ['primary-button-right'],
            action: { name: 'layout/setShowModal', payload: {} },
          },
        ],
      },
    }
    this.setRightbarContent(rightBarContent)
  },
  methods: {
    ...mapActions('layout', ['setRightbarContent']),
    ...mapActions('notifications', ['getNotification', 'setNotificationStatus']),

    /*
     * Get notification records with limit (Default Limit: 10)
     *Get range as param which contain notificationLimit and notificationSkip count
     */

    filterRecord(range) {
      this.isLoading = true
      this.getNotification({ skip: range?.skip || 0, limit: range?.limit || 10 }).then(
        (response) => {
          this.showPagination = true
          this.isLoading = false
          this.notificationCounts = response.data.total_count
          this.count = response.data.unread_count
          this.showMore = !!(this.notifications.length > 5)
        },
      )
    },

    /*
     *Perform Action on notification eg: Read & Unread
     *Get payload param contain object {notification_id:'',notification_action:''}
     *Depends on action we change the count for notification
     */

    notificationAction(payload) {
      this.setNotificationStatus({
        notification_ids: [payload?.id],
        status: payload?.action,
      }).then(() => {
        this.notifications.forEach((notify) => this.readNotification(notify, payload))
        this.newNotifications.forEach((notify) => this.readNotification(notify, payload))
      })
    },

    readNotification(notification, payload) {
      if (
        notification.id === payload.id &&
        payload.action === NOTIFICATION_STATUS.UNREAD &&
        notification.status !== NOTIFICATION_STATUS.UNREAD
      ) {
        notification.status = NOTIFICATION_STATUS.UNREAD
        this.count += 1
      } else if (
        notification.id === payload.id &&
        payload.action === NOTIFICATION_STATUS.READ &&
        notification.status !== NOTIFICATION_STATUS.READ
      ) {
        notification.status = NOTIFICATION_STATUS.READ
        this.count -= 1
      }
    },
    /*
     *Use this method to change the status of all notification to read
     *Get all the notification ids are change its status to Read
     */

    markAllAsRead() {
      var allNotificationIDs = this.notifications.map((notification) => {
        return notification.id
      })
      var allNewNotificationIDs = this.newNotifications.map((notification) => {
        return notification.id
      })
      allNotificationIDs = [...allNotificationIDs, ...allNewNotificationIDs]
      if (this.count > 0) {
        this.setNotificationStatus({
          notification_ids: allNotificationIDs,
          status: NOTIFICATION_STATUS.READ,
        }).then(() => {
          this.notifications.forEach((notification) => {
            if (notification.status !== NOTIFICATION_STATUS.READ) {
              notification.status = NOTIFICATION_STATUS.READ
              this.count = 0
            }
          })
          this.newNotifications.forEach((notification) => {
            if (notification.status !== NOTIFICATION_STATUS.READ) {
              notification.status = NOTIFICATION_STATUS.READ
              this.count = 0
            }
          })
        })
      }
    },
  },
}
</script>

<style lang="scss" module>
.scrollBarHidden {
  ::-webkit-scrollbar {
    width: 5px;
  }
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
}
</style>

<style lang="scss">
.settings-text-color {
  color: var(--text-color);
}

.notification-text-color {
  color: var(--text-color-darker);
}

.time-stamp-color {
  color: var(--text-color-grey-strong);
}

.border-bt-color {
  border-bottom-color: var(--border);
}

.base {
  background: var(--green-light);
  opacity: 1;
}
</style>
